import {
  Button,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getUsers,
  setLoader,
  setValidationErrors,
} from "../../app/store/app/appSlice";
import {
  clearSemaphore,
  createDocument,
  createInvoice,
  getCrimeInvoiceLists,
  getInvoice,
  getItemsForInvoice,
  getPrecedentList,
  reverseInvoice,
  setInit,
  setInvoice,
  setPrecedentId,
  setPrintingEnabled,
  updateInvoice,
} from "../../app/store/invoice/invoiceSlice";
import { getMatterDetails } from "../../app/store/matter/matterSlice";
import {
  IDocBuilderModelDto,
  IDocModelDto,
  IPrecedentDto,
} from "../../shared/dto/document.dto";
import {
  ICrimeStage,
  IFixedFeeExceptionItem,
  IInvoiceDto,
  IValidationDto,
  crimeBillingStageAppealsReviews,
  crimeBillingStageInvestigations,
  crimeBillingStagePrisonLaw,
  crimeBillingStateProceedings,
} from "../../shared/dto/invoice/invoice.dto";
import { useAppInit } from "../../shared/hooks/use-app-init";
import wordIcon from "../../shared/images/word.png";
import {
  calculateBalanceDue,
  createInvoiceDocument,
  printReport,
} from "../../shared/utils/invoice-utils";
import {
  close,
  getDecodedToken,
  onBeforeClose,
  openDocument,
  openReport,
  setWindowTitle,
} from "../../shared/utils/sdk-utils";
import {
  filterInactiveStaff,
  formatValue,
  getFormattedUFN,
  newGuid,
} from "../../shared/utils/utils";
import { validate } from "../../shared/utils/validation-utils";
import CustomCheckbox from "../components/custom-checkbox";
import CustomDialog from "../components/custom-dialog";
import LocalDatePicker from "../components/date-picker";
import Footer from "../components/footer";
import InvoiceErrorDialog from "../components/invoice-error-dialog";
import NumberDisplay from "../components/number-display";
import NumberInput from "../components/number-input";
import ReversalInfo from "../components/reversal-info";
import TopBar from "../components/topbar";
import UnsavedDataDialog from "../components/unsaved-data-dialog";
import { listStatusItems } from "./lists";
import InvoiceDeleteButton from "../components/invoice-delete-button";
import ReverseIcon from "../../shared/images/reverse";

const InvoiceCrimeDetails: FC = () => {
  const dispatch = useAppDispatch();

  const invoice = useAppSelector((state) => state.invoice);

  const isInvoiceDetailsInitialised = useRef(false);
  const isInvoiceInitialised = useRef(false);
  const isMatterDetailsInitialised = useRef(false);
  const isNoPSandIsINVC = useRef(false);
  const closeHandlerRegistered = useRef(false);
  const invoiceRef = useRef<IInvoiceDto | undefined>(undefined);
  const decodedToken = useRef<any>(undefined);

  const matterDetails = useSelector(
    (state: RootState) => state.matter.matterDetails
  );
  const appInit = useSelector((state: RootState) => state.app.appInit);
  const sdkApi = useSelector((state: RootState) => state.app.sdkApi);
  const saving = useSelector((state: RootState) => state.invoice.saving);
  const userList = useSelector((state: RootState) => state.app.users);
  const sdkInitialised = useSelector(
    (state: RootState) => state.app.sdkInitialised
  );

  const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState<
    IInvoiceDto | undefined
  >(undefined);
  const [notificationMessage, setNotificationMessage] = useState<
    string | undefined
  >(undefined);
  const [precedentMenuAnchorEl, setPrecedentMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const precedentMenuOpen = Boolean(precedentMenuAnchorEl);

  const [showUnsavedData, setShowUnsavedData] = useState(false);
  const [stageList, setStageList] = useState<ICrimeStage[]>([]);
  const [toBeReversed, setToBeReversed] = useState(false);

  const [searchParams] = useSearchParams();

  const infoIcon = require("../../shared/images/info.png");
  const printerIcon = require("../../shared/images/printer-grey.png");

  const convertFormModelToInvoice = (tempInvoice: IInvoiceDto): IInvoiceDto => {
    let invoice: IInvoiceDto = {
      invoiceId: tempInvoice.invoiceId,
      matterId: tempInvoice.matterId,
      externalURL: tempInvoice.externalURL,
      externalJSON: {
        openType: tempInvoice.externalJSON?.openType,
        width: tempInvoice.externalJSON?.width,
        height: tempInvoice.externalJSON?.height,
        minWidth: tempInvoice.externalJSON?.minWidth,
        minHeight: tempInvoice.externalJSON?.minHeight,
        windowTitle: tempInvoice.externalJSON?.windowTitle,
        closeHandler: tempInvoice.externalJSON?.closeHandler,
        matterTypeId: tempInvoice.externalJSON?.matterTypeId,
        billingStageId: tempInvoice.externalJSON?.billingStageId,
        ffFeeEarnerId: tempInvoice.externalJSON?.ffFeeEarnerId,
        dateConcluded: tempInvoice.externalJSON?.dateConcluded,

        fixedFeeVat: tempInvoice.externalJSON?.fixedFeeVat,
        profitCostsVat: tempInvoice.externalJSON?.profitCostsVat,
        travelVat: tempInvoice.externalJSON?.travelVat,
        waitingVat: tempInvoice.externalJSON?.waitingVat,

        fixedFee: tempInvoice.externalJSON?.fixedFee,
        profitCosts: tempInvoice.externalJSON?.profitCosts,
        travelCosts: tempInvoice.externalJSON?.travelCosts,
        waitingCosts: tempInvoice.externalJSON?.waitingCosts,
        disbExVat: tempInvoice.externalJSON?.disbExVat,
        disbVat: tempInvoice.externalJSON?.disbVat,
        stage: tempInvoice.externalJSON?.stage,
        outcome: tempInvoice.externalJSON?.outcome,
        adviceTime: tempInvoice.externalJSON?.adviceTime,
        travelTime: tempInvoice.externalJSON?.travelTime,
        waitingTime: tempInvoice.externalJSON?.waitingTime,
        isClaimExceptional: tempInvoice.externalJSON?.isClaimExceptional,
        fixedFeeValue: tempInvoice.externalJSON?.fixedFeeValue,
        fixedFeeLimit: tempInvoice.externalJSON?.fixedFeeLimit,
        crime: {
          standardFeeCat: tempInvoice.externalJSON?.crime?.standardFeeCat,
          offence: tempInvoice.externalJSON?.crime?.offence,
          dutySol: tempInvoice.externalJSON?.crime?.dutySol,
          youthCourt: tempInvoice.externalJSON?.crime?.youthCourt,
          defendants: tempInvoice.externalJSON?.crime?.defendants,
          attendances: tempInvoice.externalJSON?.crime?.attendances,
          undesignatedArea: tempInvoice.externalJSON?.crime?.undesignatedArea,
          policeStationIncludeTravel:
            tempInvoice.externalJSON?.crime?.policeStationIncludeTravel,
        },
      },
      invoiceDate: tempInvoice.invoiceDate,
      invoiceNumber: tempInvoice.invoiceNumber,
      invoiceDesc: tempInvoice.invoiceDesc,
      timeRecordGuids: tempInvoice.timeRecordGuids,
      reversalId: tempInvoice.reversalId,
      reversedOrReversal: tempInvoice.reversedOrReversal,
      semaphoreId: tempInvoice.semaphoreId,
      warningAcknowledgments: tempInvoice.warningAcknowledgments,
      status: tempInvoice.status,
      deleted: tempInvoice.deleted,
      totalAdjustments: tempInvoice.totalAdjustments,
      totalApplied: tempInvoice.totalApplied,
    };
    return invoice;
  };

  const [feeEarnerValidator, setFeeEarnerValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: true,
    isRequired: false,
  });
  const stageEarnerValidator: IValidationDto = {
    error: "",
    isDisabled: false,
    isRequired: true,
  };
  const dateConcludedValidator: IValidationDto = {
    error: "",
    isDisabled: false,
    isRequired: true,
  };
  const dateBilledValidator: IValidationDto = {
    error: "",
    isDisabled: false,
    isRequired: true,
  };
  const [offenceValidator, setOffenceValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: false,
  });

  const [outcomeValidator, setOutcomeValidator] = useState<IValidationDto>({
    error: "",
    isDisabled: false,
    isRequired: false,
  });

  const isFormDisabled =
    invoice.init.isReversedOrReversal ||
    Boolean(currentInvoiceDetails?.reversedOrReversal) ||
    !!invoice.init.invoiceId;

  const columns: GridColDef[] = [
    {
      disableColumnMenu: true,
      field: "id",
      headerName: "ID",
      width: 0,
    },
    {
      disableColumnMenu: true,
      field: "description",
      flex: 1,
      headerName: "DESCRIPTION",
      sortable: false,
      renderCell: (params: any) => {
        if (params.value === "Fixed Fee") {
          return (
            <>
              {params.value}
              <Tooltip
                style={{ paddingLeft: 5 }}
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    Fixed Fee:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFee,
                        invoice.init.isReversedOrReversal
                      )}
                    <br />
                    Fee Limit:
                    {" " +
                      formatValue(
                        currentInvoiceDetails?.externalJSON?.fixedFeeLimit,
                        invoice.init.isReversedOrReversal
                      )}
                  </div>
                }
              >
                <img alt="warning" src={infoIcon} />
              </Tooltip>
              {currentInvoiceDetails?.externalJSON?.isClaimExceptional &&
                currentInvoiceDetails.externalJSON.fixedFeeValue !== 0 && (
                  <Typography color="grey" marginLeft={6}>
                    (This claim is Exceptional)
                  </Typography>
                )}
              {isNoPSandIsINVC.current && (
                <Typography color="red" marginLeft={6}>
                  No Police Station Assigned
                </Typography>
              )}
            </>
          );
        }

        return params.value;
      },
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "time",
      headerAlign: "center",
      headerName: "TIME",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          notCurrency
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "amount",
      headerAlign: "center",
      headerName: "AMOUNT",
      sortable: false,
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
    {
      align: "right",
      disableColumnMenu: true,
      field: "tax",
      headerAlign: "center",
      headerName: "TAX",
      sortable: false,
      type: "number",
      renderCell: (params) => (
        <NumberDisplay
          value={params.value}
          showPrefix
          showNegative={
            (!currentInvoiceDetails?.reversalId &&
              (invoice.init.isReversedOrReversal ||
                Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
            toBeReversed
          }
          hideNoValue
        />
      ),
    },
  ];

  const handleClose = useCallback(() => {
    if (invoiceRef.current && invoiceRef.current.semaphoreId) {
      dispatch(clearSemaphore(invoiceRef.current.semaphoreId)).then(() => {
        close(sdkApi);
      });
    } else {
      close(sdkApi);
    }
  }, [dispatch, sdkApi]);

  const getData = useCallback(() => {
    if (!isInvoiceInitialised.current) {
      getDecodedToken(sdkApi).then((payload) => {
        decodedToken.current = payload;
      });
      isInvoiceInitialised.current = true;
      dispatch(
        setInit({
          billingStageId: searchParams.get("billingstageid"),
          invoiceId: searchParams.get("invoiceid"),
          isReversedOrReversal: searchParams.get("reversal")
            ? searchParams.get("reversal")
            : false,
          matterTypeId: appInit?.laMatterTypeId,
          matterTypeName: appInit?.laMatterTypeName,
        })
      );

      dispatch(getMatterDetails(false)).then(() => {
        dispatch(getUsers());
      });
    }
  }, [dispatch, appInit, searchParams, sdkApi]);

  useAppInit(() => {
    getData();
  });

  useEffect(() => {
    if (!!sdkApi && !!sdkInitialised && !!appInit) {
      getData();
    }
  }, [dispatch, sdkApi, sdkInitialised, getData, appInit]);

  useEffect(() => {
    if (!!matterDetails && !isMatterDetailsInitialised.current) {
      isMatterDetailsInitialised.current = true;
      dispatch(
        getCrimeInvoiceLists({
          matterId: matterDetails?.matterId,
          matterTypeId: matterDetails?.legalAidObj?.matterTypeId,
          tableNo: matterDetails?.legalAidObj?.tableNo,
          rate: matterDetails?.legalAidObj?.rate,
          undesignatedArea:
            matterDetails?.legalAidObj?.criminal?.undesignatedArea,
          billingStageId: invoice?.init?.billingStageId,
          tableNoRepOrder: matterDetails?.legalAidObj?.tableNoRepOrder,
        })
      )
        .then(() => {
          dispatch(
            getPrecedentList({
              matterTypeId: matterDetails?.legalAidObj?.matterTypeId,
              billingStageId: invoice?.init?.billingStageId,
            })
          );
        })
        .then(() => {
          if (invoice.init.invoiceId) {
            dispatch(getInvoice(invoice.init.invoiceId)).then((action) => {
              setWindowTitle(
                `${matterDetails?.matterReference || ""} ${
                  matterDetails?.matterDescription || ""
                }`,
                sdkApi,
                60,
                action.payload.externalJSON?.windowTitle
                  ? `- ${action.payload.externalJSON?.windowTitle}`
                  : ""
              );
            });
          } else if (invoice.init.billingStageId) {
            dispatch(
              getItemsForInvoice({
                matterId: matterDetails?.matterId,
                billingStageId: invoice?.init.billingStageId,
                disbursementsOnly: false,
              })
            );
            setWindowTitle(
              `${matterDetails?.matterReference || ""} ${
                matterDetails?.matterDescription || ""
              }`,
              sdkApi,
              60,
              "- New Invoice"
            );
          }
        });
    }
  }, [
    dispatch,
    matterDetails,
    invoice.init,
    isMatterDetailsInitialised,
    sdkApi,
  ]);

  useEffect(() => {
    if (!!invoice.invoice && !isInvoiceDetailsInitialised.current) {
      isInvoiceDetailsInitialised.current = true;

      if (invoice.crimeLists?.categories.length === 1) {
        const tempInvoice = {
          ...invoice.invoice,
          externalJSON: {
            ...invoice.invoice.externalJSON,
            crime: {
              ...invoice.invoice.externalJSON?.crime,
              standardFeeCat: invoice.crimeLists?.categories[0].code,
            },
          },
        } as IInvoiceDto;
        setCurrentInvoiceDetails(tempInvoice);
        invoiceRef.current = tempInvoice;
        dispatch(setInvoice(tempInvoice));
        setStageList(invoice.crimeLists?.categories[0].stages);
      } else {
        setCurrentInvoiceDetails(invoice.invoice);
        invoiceRef.current = invoice.invoice;
        if (invoice.invoice && invoice.crimeLists?.categories) {
          const catGroup = invoice.crimeLists.categories.find(
            (a) =>
              a.code === invoice.invoice?.externalJSON?.crime?.standardFeeCat
          );
          if (catGroup && catGroup.stages) {
            setStageList(catGroup.stages);
          }
        }
      }
    }
  }, [
    currentInvoiceDetails,
    invoice.crimeLists?.categories,
    invoice.invoice,
    isInvoiceDetailsInitialised,
    dispatch,
  ]);

  useEffect(() => {
    invoiceRef.current = currentInvoiceDetails;
  }, [currentInvoiceDetails]);

  useEffect(() => {
    if (!closeHandlerRegistered.current && !!sdkApi && !!invoice.invoice) {
      closeHandlerRegistered.current = true;
      onBeforeClose(() => {
        if (
          JSON.stringify(invoice.invoice) !== JSON.stringify(invoiceRef.current)
        ) {
          setShowUnsavedData(true);
        } else {
          handleClose();
        }
      }, sdkApi);
    }
  }, [sdkApi, invoice.invoice, handleClose]);

  const getTotals = (billingStageId: number) => {
    let calculationLabel: string = "";
    let totalCost: number = 0.0;
    let totalTime =
      (currentInvoiceDetails?.externalJSON?.adviceTime || 0) +
      (currentInvoiceDetails?.externalJSON?.travelTime || 0) +
      (currentInvoiceDetails?.externalJSON?.waitingTime || 0);
    let totalVat: number = 0.0;

    switch (billingStageId.toString()) {
      case crimeBillingStageInvestigations.toString():
        if (currentInvoiceDetails?.externalJSON?.isClaimExceptional) {
          totalVat =
            (currentInvoiceDetails?.externalJSON?.profitCostsVat || 0) +
            (currentInvoiceDetails?.externalJSON?.travelVat || 0) +
            (currentInvoiceDetails.externalJSON?.waitingVat || 0);
          totalCost =
            (currentInvoiceDetails?.externalJSON?.profitCosts || 0) +
            (currentInvoiceDetails?.externalJSON?.travelCosts || 0) +
            (currentInvoiceDetails?.externalJSON?.waitingCosts || 0);
          calculationLabel = "Profit Cost + Travel + Waiting";
        } else {
          if ((currentInvoiceDetails?.externalJSON?.fixedFee || 0) > 0) {
            totalVat = currentInvoiceDetails?.externalJSON?.fixedFeeVat || 0;
            totalCost = currentInvoiceDetails?.externalJSON?.fixedFee || 0;
            calculationLabel = "Fixed Fee";
            if (
              matterDetails?.legalAidObj?.criminal?.policeStationIncludeTravel
            ) {
              calculationLabel = "Fixed Fee + Travel";
              totalVat += currentInvoiceDetails?.externalJSON?.travelVat || 0;
              totalCost +=
                currentInvoiceDetails?.externalJSON?.travelCosts || 0;
            }
          } else {
            totalVat =
              (currentInvoiceDetails?.externalJSON?.profitCostsVat || 0) +
              (currentInvoiceDetails?.externalJSON?.travelVat || 0) +
              (currentInvoiceDetails?.externalJSON?.waitingVat || 0);
            totalCost =
              (currentInvoiceDetails?.externalJSON?.profitCosts || 0) +
              (currentInvoiceDetails?.externalJSON?.travelCosts || 0) +
              (currentInvoiceDetails?.externalJSON?.waitingCosts || 0);
            calculationLabel = "Profit Cost + Travel + Waiting";
          }
        }
        break;
      case crimeBillingStateProceedings.toString():
        if (currentInvoiceDetails?.externalJSON?.isClaimExceptional) {
          totalVat = currentInvoiceDetails?.externalJSON?.profitCostsVat || 0;
          totalCost = currentInvoiceDetails?.externalJSON?.profitCosts || 0;
          calculationLabel = "Profit Cost";
          if ((currentInvoiceDetails?.externalJSON?.fixedFee || 0) > 0) {
            calculationLabel = "Fixed Fee + Profit Cost";
            totalVat += currentInvoiceDetails?.externalJSON?.fixedFeeVat || 0;
            totalCost += currentInvoiceDetails?.externalJSON?.fixedFee || 0;
          }
        } else {
          if ((currentInvoiceDetails?.externalJSON?.fixedFee || 0) > 0) {
            totalVat = currentInvoiceDetails?.externalJSON?.fixedFeeVat || 0;
            totalCost = currentInvoiceDetails?.externalJSON?.fixedFee || 0;
            calculationLabel = "Fixed Fee";
          } else {
            totalVat = currentInvoiceDetails?.externalJSON?.profitCostsVat || 0;
            totalCost = currentInvoiceDetails?.externalJSON?.profitCosts || 0;
            calculationLabel = "Profit Cost";
          }
        }
        if (
          ((currentInvoiceDetails?.externalJSON?.stage === "PROE" ||
            currentInvoiceDetails?.externalJSON?.stage === "PROF" ||
            currentInvoiceDetails?.externalJSON?.stage === "PROJ" ||
            currentInvoiceDetails?.externalJSON?.stage === "PROM" ||
            currentInvoiceDetails?.externalJSON?.stage === "YOUE" ||
            currentInvoiceDetails?.externalJSON?.stage === "YOUF" ||
            currentInvoiceDetails?.externalJSON?.stage === "YOUX") &&
            currentInvoiceDetails?.externalJSON?.crime?.undesignatedArea) ||
          currentInvoiceDetails?.externalJSON?.stage === "PROC" ||
          currentInvoiceDetails?.externalJSON?.stage === "PROD" ||
          currentInvoiceDetails?.externalJSON?.stage === "PROG" ||
          currentInvoiceDetails?.externalJSON?.stage === "PROH" ||
          currentInvoiceDetails?.externalJSON?.stage === "PROI" ||
          (currentInvoiceDetails?.externalJSON?.stage === "PROV" &&
            currentInvoiceDetails?.externalJSON.crime?.offence === "36")
        ) {
          totalVat +=
            (currentInvoiceDetails.externalJSON.travelVat || 0) +
            (currentInvoiceDetails.externalJSON.waitingVat || 0);
          totalCost +=
            (currentInvoiceDetails.externalJSON.travelCosts || 0) +
            (currentInvoiceDetails.externalJSON.waitingCosts || 0);
          calculationLabel += " + Travel + Waiting";
        }
        break;
      case crimeBillingStageAppealsReviews.toString():
        if ((currentInvoiceDetails?.externalJSON?.fixedFee || 0) > 0) {
          totalVat = currentInvoiceDetails?.externalJSON?.fixedFeeVat || 0;
          totalCost = currentInvoiceDetails?.externalJSON?.fixedFee || 0;
          calculationLabel = "Fixed Fee";
        } else {
          totalVat =
            (currentInvoiceDetails?.externalJSON?.profitCostsVat || 0) +
            (currentInvoiceDetails?.externalJSON?.travelVat || 0) +
            (currentInvoiceDetails?.externalJSON?.waitingVat || 0);
          totalCost =
            (currentInvoiceDetails?.externalJSON?.profitCosts || 0) +
            (currentInvoiceDetails?.externalJSON?.travelCosts || 0) +
            (currentInvoiceDetails?.externalJSON?.waitingCosts || 0);
          calculationLabel = "Profit Cost + Travel + Waiting";
        }
        break;
      case crimeBillingStagePrisonLaw.toString():
        if (currentInvoiceDetails?.externalJSON?.isClaimExceptional) {
          totalVat =
            (currentInvoiceDetails?.externalJSON?.profitCostsVat || 0) +
            (currentInvoiceDetails?.externalJSON?.travelVat || 0) +
            (currentInvoiceDetails?.externalJSON?.waitingVat || 0);
          totalCost =
            (currentInvoiceDetails?.externalJSON.profitCosts || 0) +
            (currentInvoiceDetails?.externalJSON?.travelCosts || 0) +
            (currentInvoiceDetails?.externalJSON?.waitingCosts || 0);
          calculationLabel = "Profit Cost + Travel + Waiting";
        } else {
          if ((currentInvoiceDetails?.externalJSON?.fixedFee || 0) > 0) {
            totalVat = currentInvoiceDetails?.externalJSON?.fixedFeeVat || 0;
            totalCost = currentInvoiceDetails?.externalJSON?.fixedFee || 0;
            calculationLabel = "Fixed Fee";
          } else {
            totalVat =
              (currentInvoiceDetails?.externalJSON?.profitCostsVat || 0) +
              (currentInvoiceDetails?.externalJSON?.travelVat || 0) +
              (currentInvoiceDetails?.externalJSON?.waitingVat || 0);
            totalCost =
              (currentInvoiceDetails?.externalJSON?.profitCosts || 0) +
              (currentInvoiceDetails?.externalJSON?.travelCosts || 0) +
              (currentInvoiceDetails?.externalJSON?.waitingCosts || 0);
            calculationLabel = "Profit Cost + Travel + Waiting";
          }
        }

        break;
    }

    calculationLabel += " + Disbursements";
    totalCost += currentInvoiceDetails?.externalJSON?.disbExVat || 0;
    totalVat += currentInvoiceDetails?.externalJSON?.disbVat || 0;

    return {
      totalCost,
      totalTime,
      totalVat,
      calculationLabel,
    };
  };

  const checkFixedFee = (stageList: ICrimeStage[], stage?: string) => {
    const newStage = stageList.find((a) => a.code === stage);
    const fixedFee = getFixedFee(
      invoice.init.billingStageId,
      currentInvoiceDetails?.externalJSON?.profitCosts || 0,
      currentInvoiceDetails?.externalJSON?.travelCosts || 0,
      currentInvoiceDetails?.externalJSON?.waitingCosts || 0,
      newStage
    );

    return fixedFee;
  };

  const getFixedFee = (
    billingStageId: number,
    profitCost: number,
    travelCost: number,
    waitingCost: number,
    stage?: ICrimeStage
  ): IFixedFeeExceptionItem => {
    let fixedFee = 0;
    let fixedFeeValue = 0;
    let fixedFeeLimit = 0;
    let isClaimExceptional = false;

    if (stage && stage !== null) {
      switch (billingStageId.toString()) {
        case crimeBillingStageInvestigations.toString():
          switch (stage.code) {
            case "INVC":
            case "INVD":
            case "INVJ":
              if (!matterDetails?.legalAidObj?.criminal?.policeStation) {
                isNoPSandIsINVC.current = true;
              }
              fixedFee = stage.fixedFee || 0;
              fixedFeeValue = stage.fixedFee || 0;
              fixedFeeLimit = stage.fixedFeeLimit || 0;
              if (profitCost >= stage.fixedFeeLimit) {
                isClaimExceptional = true;
                fixedFee = 0;
              }
              break;
            case "INVA":
            case "INVE":
            case "INVF":
            case "INVH":
            case "INVK":
            case "INVL":
              if (profitCost + travelCost + waitingCost > stage.fee) {
                fixedFee = stage.fee;
                fixedFeeValue = stage.fee;
              }
              break;
            case "INVM":
              if (profitCost > stage.fee) {
                fixedFee = stage.fee;
                fixedFeeValue = stage.fee;
              }
              break;
            default:
              break;
          }
          break;
        case crimeBillingStateProceedings.toString():
          switch (stage.code) {
            case "PROE":
            case "PROF":
            case "PROJ":
            case "PROK":
            case "PROL":
            case "PROV":
            case "YOUE":
            case "YOUF":
            case "YOUK":
            case "YOUL":
            case "YOUX":
            case "YOUY":
              if (stage.hasRates) {
                if (profitCost <= stage.lowerLimit) {
                  fixedFee = stage.lowerFee;
                  fixedFeeValue = stage.lowerFee;
                  fixedFeeLimit = stage.lowerLimit;
                } else if (
                  profitCost > stage.lowerLimit &&
                  profitCost <= stage.higherLimit
                ) {
                  fixedFee = stage.higherFee;
                  fixedFeeValue = stage.higherFee;
                  fixedFeeLimit = stage.higherLimit;
                } else if (profitCost > stage.higherLimit) {
                  fixedFeeValue = stage.higherFee;
                  fixedFeeLimit = stage.higherLimit;
                  isClaimExceptional = true;
                  fixedFee = stage.fee; //set to fixed fee if present
                }
              }
              break;
            case "PROT":
            case "PROU":
            case "PROW":
              fixedFee = stage.fee;
              fixedFeeValue = stage.fee;
              break;
            case "PROP":
              if (profitCost >= stage.fee) {
                fixedFee = stage.fee;
                fixedFeeValue = stage.fee;
              }
              break;
            case "PROH":
              if (profitCost > stage.fee) {
                fixedFee = stage.fee;
                fixedFeeValue = stage.fee;
              }
              break;
            default:
              break;
          }
          break;
        case crimeBillingStageAppealsReviews.toString():
          fixedFeeLimit = stage.fee;
          break;
        case crimeBillingStagePrisonLaw.toString():
          if (stage.hasRates) {
            if (profitCost < stage.fee) {
              fixedFee = 0;
              fixedFeeValue = stage.fee;
              fixedFeeLimit = stage.lowerLimit;
            } else if (profitCost + waitingCost <= stage.lowerLimit) {
              fixedFee = stage.lowerFee;
              fixedFeeValue = stage.lowerFee;
              fixedFeeLimit = stage.lowerLimit;
            } else if (
              profitCost + waitingCost > stage.lowerLimit &&
              profitCost + waitingCost <= stage.higherLimit
            ) {
              fixedFee = stage.higherFee;
              fixedFeeValue = stage.higherFee;
              fixedFeeLimit = stage.higherLimit;
            } else if (profitCost + waitingCost > stage.higherLimit) {
              fixedFee = 0;
              isClaimExceptional = true;
              fixedFeeValue = stage.higherFee;
              fixedFeeLimit = stage.higherLimit;
            } else {
              fixedFee = stage.higherFee;
              fixedFeeValue = stage.higherFee;
              fixedFeeLimit = stage.higherLimit;
            }
          }
          break;
        default:
          break;
      }
    }
    if (fixedFee == null) {
      fixedFee = 0;
    }

    return {
      fixedFee,
      fixedFeeVat: fixedFee * (matterDetails?.taxFree ? 0 : 0.2),
      isClaimExceptional,
      fixedFeeValue,
      fixedFeeLimit,
    };
  };

  const updateFixedFee = (
    item: IFixedFeeExceptionItem,
    standardFeeCat?: string,
    stage?: string
  ) => {
    if (
      item.fixedFee > 0 &&
      !item.isClaimExceptional &&
      !invoice.init.isReversedOrReversal
    ) {
      setFeeEarnerValidator({
        ...feeEarnerValidator,
        isDisabled: false,
        isRequired: true,
      });
    } else {
      setFeeEarnerValidator({
        ...feeEarnerValidator,
        isDisabled: true,
        isRequired: false,
      });
    }

    setCurrentInvoiceDetails({
      ...currentInvoiceDetails,
      externalJSON: {
        ...currentInvoiceDetails?.externalJSON,
        fixedFee: item.fixedFee,
        fixedFeeVat: item.fixedFeeVat,
        isClaimExceptional: item.isClaimExceptional,
        fixedFeeValue: item.fixedFeeValue,
        fixedFeeLimit: item.fixedFeeLimit,
        stage: stage,
        crime: {
          ...currentInvoiceDetails?.externalJSON?.crime,
          standardFeeCat: standardFeeCat,
        },
      },
    });
  };

  const handleOnChangeCategory = (value: any) => {
    let stage = currentInvoiceDetails?.externalJSON?.stage;
    let stages: any = [];

    if (value && invoice.crimeLists?.categories) {
      const catGroup = invoice.crimeLists.categories.find(
        (a) => a.code === value
      );

      if (catGroup && catGroup.stages) {
        if (!catGroup.stages.find((s) => s.code === stage)) {
          stage = undefined;
        }
        stages = catGroup.stages;
      }
    }

    setStageList(stages);
    const fixedFee = checkFixedFee(stages, stage);
    updateFixedFee(fixedFee, value, stage);
  };

  const handleOnChangeStage = (value: string) => {
    switch (value) {
      case "INVH":
      case "INVI":
      case "PROC":
      case "PROD":
      case "PROH":
      case "PROI":
      case "PROP":
      case "PROT":
      case "PROU":
      case "PROW":
      case "PROJ":
      case "YOUX":
      case "YOUY":
      case "APPA":
      case "APPB":
      case "APPC":
      case "ASSA": {
        setOffenceValidator({
          ...offenceValidator,
          error: "",
          isDisabled: false,
          isRequired: false,
        });
        setOutcomeValidator({
          ...offenceValidator,
          error: "",
          isDisabled: false,
          isRequired: false,
        });
        break;
      }
      case "FRVA":
        if (invoice.init.billingStageId !== crimeBillingStateProceedings) {
          setOffenceValidator({
            ...offenceValidator,
            error: "",
            isDisabled: false,
            isRequired: false,
          });
          setOutcomeValidator({
            ...offenceValidator,
            error: "",
            isDisabled: false,
            isRequired: false,
          });
        }
        break;
      default: {
        setOffenceValidator({
          ...offenceValidator,
          error: "",
          isDisabled: false,
          isRequired: true,
        });
        setOutcomeValidator({
          ...offenceValidator,
          error: "",
          isDisabled: false,
          isRequired: true,
        });
        break;
      }
    }
  };

  const getRequiredFields = (data: IInvoiceDto) => {
    let fields = [];

    if (feeEarnerValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.ffFeeEarnerId,
        label: "Fee Earner",
      });

    if (stageEarnerValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.stage,
        label: "Stage",
      });
    if (dateConcludedValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.dateConcluded,
        label: "Date Concluded",
      });
    if (dateBilledValidator.isRequired)
      fields.push({
        value: data?.invoiceDate,
        label: "Date Billed",
      });

    if (offenceValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.crime?.offence,
        label: "Offence Code",
      });

    if (outcomeValidator.isRequired)
      fields.push({
        value: data?.externalJSON?.outcome,
        label: "Outcome Code",
      });

    return fields;
  };

  const renderCriteria = () => {
    return (
      <>
        <div className="displayFlex">
          <div style={{ width: 675 }}>
            <div className="input-row-aligned">
              <div style={{ width: 110 }}>
                <InputLabel>Matter</InputLabel>
              </div>
              <div className="flex3" style={{ display: "inline-flex" }}>
                <TextField
                  disabled
                  value={matterDetails?.matterReference || ""}
                  variant="outlined"
                  style={{ width: 110 }}
                />
                <Typography
                  style={{ marginTop: 3, marginLeft: 5 }}
                  maxWidth={454}
                  minWidth={454}
                  noWrap
                >
                  {matterDetails?.matterDescription}
                </Typography>
              </div>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 260, paddingLeft: 25 }}>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Invoice No.</InputLabel>
              </div>
              <div className="flex1">
                <TextField
                  disabled
                  placeholder="Auto no"
                  variant="outlined"
                  value={currentInvoiceDetails?.invoiceNumber || ""}
                  style={{ width: 108 }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="displayFlex">
          <div style={{ width: 425 }}>
            <div className="input-row-aligned">
              <div className="flex1">
                <InputLabel>Category</InputLabel>
              </div>
              <div className="flex3">
                <Select
                  disabled={
                    invoice.crimeLists?.categories?.length === 1 ||
                    isFormDisabled
                  }
                  onChange={(e: any) => {
                    handleOnChangeCategory(e.target.value);
                  }}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.crime
                      ?.standardFeeCat || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {(!currentInvoiceDetails?.externalJSON?.crime
                    ?.standardFeeCat &&
                    currentInvoiceDetails?.externalJSON?.crime
                      ?.standardFeeCat) !== "" && <option value=""></option>}
                  {invoice.crimeLists?.categories?.map(
                    (item: any, index: number) => (
                      <option
                        key={`invoiceCategory-${index}`}
                        value={item.code}
                      >
                        {item.code} {item.description}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex1">
                <InputLabel required={offenceValidator.isRequired}>
                  Offence
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  disabled={
                    offenceValidator.isDisabled ||
                    isFormDisabled ||
                    !invoice.crimeLists?.offences.length
                  }
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        crime: {
                          ...currentInvoiceDetails?.externalJSON?.crime,
                          offence: e.target.value,
                        },
                      },
                    });
                  }}
                  required={offenceValidator.isRequired}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.crime?.offence || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {!currentInvoiceDetails?.externalJSON?.crime?.offence &&
                    currentInvoiceDetails?.externalJSON?.crime?.offence !==
                      "" && <option value=""></option>}
                  {invoice.crimeLists?.offences.map(
                    (item: any, index: number) => (
                      <option key={`invoiceOffence-${index}`} value={item.code}>
                        {item.code} {item.description}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex1">
                <InputLabel required={outcomeValidator.isRequired}>
                  Outcome
                </InputLabel>
              </div>
              <div className="flex3">
                <Select
                  disabled={
                    outcomeValidator.isDisabled ||
                    isFormDisabled ||
                    !invoice.crimeLists?.outcomes.length
                  }
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        outcome: e.target.value,
                      },
                    });
                  }}
                  required={outcomeValidator.isRequired}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  value={currentInvoiceDetails?.externalJSON?.outcome || ""}
                  variant="outlined"
                  native
                  className="select"
                >
                  {!currentInvoiceDetails?.externalJSON?.outcome &&
                    currentInvoiceDetails?.externalJSON?.outcome !== "" && (
                      <option value=""></option>
                    )}
                  {invoice.crimeLists?.outcomes?.map(
                    (item: any, index: number) => (
                      <option key={`invoiceOutcome-${index}`} value={item.code}>
                        {item.code} {item.description}
                      </option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex1">
                <InputLabel required>Stage</InputLabel>
              </div>
              <div className="flex3">
                <Select
                  onChange={(e: any) => {
                    handleOnChangeStage(e.target.value);
                    const fixedFee = checkFixedFee(stageList, e.target.value);
                    updateFixedFee(
                      fixedFee,
                      currentInvoiceDetails?.externalJSON?.crime
                        ?.standardFeeCat,
                      e.target.value
                    );
                  }}
                  sx={{
                    maxWidth: 315,
                    minWidth: 315,
                  }}
                  disabled={isFormDisabled}
                  value={currentInvoiceDetails?.externalJSON?.stage || ""}
                  variant="outlined"
                  native
                  className="select"
                >
                  {!currentInvoiceDetails?.externalJSON?.stage &&
                    currentInvoiceDetails?.externalJSON?.stage !== "" && (
                      <option value=""></option>
                    )}
                  {stageList?.map((item: any, index: number) => (
                    <option key={`stage-${index}`} value={item.code}>
                      {item.code} {item.description}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 250 }}>
            <div>
              <div className="input-row-aligned">
                <div className="flex1">
                  <InputLabel>UFN</InputLabel>
                </div>
                <div className="flex1">
                  <TextField
                    disabled
                    fullWidth
                    value={
                      matterDetails?.legalAidObj?.ufnNumber
                        ? getFormattedUFN(
                            matterDetails?.legalAidObj?.ufnNumber,
                            matterDetails?.legalAidObj?.ufnDate
                          )
                        : ""
                    }
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="input-row-aligned">
                <div className="flex4">
                  <InputLabel>Defendants</InputLabel>
                </div>
                <div className="flex1">
                  <NumberInput
                    disabled={isFormDisabled}
                    updater={(value) => {
                      setCurrentInvoiceDetails({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crime: {
                            ...currentInvoiceDetails?.externalJSON?.crime,
                            defendants: Number(value),
                          },
                        },
                      });
                    }}
                    decimalScale={0}
                    value={
                      currentInvoiceDetails?.externalJSON?.crime?.defendants ||
                      0
                    }
                    width={70}
                  />
                </div>
              </div>
              <div className="input-row-aligned">
                <div className="flex4">
                  <InputLabel>Attendances</InputLabel>
                </div>
                <div className="flex1">
                  <NumberInput
                    disabled={isFormDisabled}
                    updater={(value) => {
                      setCurrentInvoiceDetails({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crime: {
                            ...currentInvoiceDetails?.externalJSON?.crime,
                            attendances: value,
                          },
                        },
                      });
                    }}
                    decimalScale={0}
                    value={
                      currentInvoiceDetails?.externalJSON?.crime?.attendances ||
                      0
                    }
                    width={70}
                  />
                </div>
              </div>
              <div className="input-row-aligned">
                <div className="flex3">
                  <CustomCheckbox
                    label="Duty Solicitor"
                    disabled={isFormDisabled}
                    onChange={(value: any) => {
                      setCurrentInvoiceDetails({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crime: {
                            ...currentInvoiceDetails?.externalJSON?.crime,
                            dutySol: value,
                          },
                        },
                      });
                    }}
                    value={
                      currentInvoiceDetails?.externalJSON?.crime?.dutySol ||
                      false
                    }
                  />
                </div>
                <div className="flex3">
                  <CustomCheckbox
                    label="Youth Court"
                    disabled={isFormDisabled}
                    onChange={(value: any) => {
                      setCurrentInvoiceDetails({
                        ...currentInvoiceDetails,
                        externalJSON: {
                          ...currentInvoiceDetails?.externalJSON,
                          crime: {
                            ...currentInvoiceDetails?.externalJSON?.crime,
                            youthCourt: value,
                          },
                        },
                      });
                    }}
                    value={
                      currentInvoiceDetails?.externalJSON?.crime?.youthCourt ||
                      false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex1" />
          <div style={{ width: 260, paddingLeft: 25 }}>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required>Date Concluded</InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled}
                  onUpdate={(value: any) => {
                    if (
                      value !==
                      currentInvoiceDetails?.externalJSON?.dateConcluded
                    ) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          externalJSON: {
                            ...currentInvoiceDetails?.externalJSON,
                            dateConcluded: newValue,
                          },
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.dateConcluded
                      ? currentInvoiceDetails.externalJSON?.dateConcluded
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required>Date Billed</InputLabel>
              </div>
              <div className="flex1" style={{ marginRight: 4 }}>
                <LocalDatePicker
                  disabled={isFormDisabled && !toBeReversed}
                  onUpdate={(value: any) => {
                    if (value !== currentInvoiceDetails?.invoiceDate) {
                      const newValue = value || "";
                      if (newValue !== "Invalid date") {
                        setCurrentInvoiceDetails({
                          ...currentInvoiceDetails,
                          invoiceDate: newValue,
                        });
                      }
                    }
                  }}
                  value={
                    currentInvoiceDetails?.invoiceDate
                      ? currentInvoiceDetails.invoiceDate
                      : null
                  }
                />
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel>Status</InputLabel>
              </div>
              <div className="flex4">
                <Select
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      status: e.target.value,
                    });
                  }}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  disabled={
                    isFormDisabled && currentInvoiceDetails?.status === 3
                  }
                  value={
                    !!currentInvoiceDetails?.status ||
                    currentInvoiceDetails?.status === 0
                      ? currentInvoiceDetails?.status
                      : ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {listStatusItems.map((item: any, index: number) => (
                    <option key={`invoiceStatus-${index}`} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="input-row-aligned">
              <div className="flex2">
                <InputLabel required={feeEarnerValidator.isRequired}>
                  Staff
                  <Tooltip
                    style={{ paddingLeft: 5 }}
                    title="Defines who will be allocated to the fixed fee. Only relevant if a fixed fee applies."
                  >
                    <img alt="warning" src={infoIcon} />
                  </Tooltip>
                </InputLabel>
              </div>
              <div className="flex4">
                <Select
                  disabled={feeEarnerValidator.isDisabled || isFormDisabled}
                  onChange={(e: any) => {
                    setCurrentInvoiceDetails({
                      ...currentInvoiceDetails,
                      externalJSON: {
                        ...currentInvoiceDetails?.externalJSON,
                        ffFeeEarnerId: e.target.value,
                      },
                    });
                  }}
                  required={feeEarnerValidator.isRequired}
                  sx={{
                    maxWidth: 170,
                    minWidth: 170,
                  }}
                  value={
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId || ""
                  }
                  variant="outlined"
                  native
                  className="select"
                >
                  {filterInactiveStaff(
                    userList,
                    currentInvoiceDetails?.externalJSON?.ffFeeEarnerId
                  )?.map((item: any, index: number) => (
                    <option key={`staff-${index}`} value={item.staffId}>
                      {item.firstName} {item.lastName}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderBreakdown = () => {
    return (
      <DataGridPro
        columns={columns}
        columnHeaderHeight={30}
        columnVisibilityModel={{
          id: false,
        }}
        disableRowSelectionOnClick
        getRowId={(row: any) => row.id}
        hideFooter
        initialState={{}}
        rowHeight={26}
        rows={[
          {
            id: newGuid(),
            description: "Fixed Fee",
            amount: currentInvoiceDetails?.externalJSON?.fixedFee,
            tax: currentInvoiceDetails?.externalJSON?.fixedFeeVat,
          },
          {
            id: newGuid(),
            description: "Profit Cost",
            time: currentInvoiceDetails?.externalJSON?.adviceTime,
            amount: currentInvoiceDetails?.externalJSON?.profitCosts,
            tax: currentInvoiceDetails?.externalJSON?.profitCostsVat,
          },
          {
            id: newGuid(),
            description: "Travel",
            time: currentInvoiceDetails?.externalJSON?.travelTime,
            amount: currentInvoiceDetails?.externalJSON?.travelCosts,
            tax: currentInvoiceDetails?.externalJSON?.travelVat,
          },
          {
            id: newGuid(),
            description: "Waiting",
            time: currentInvoiceDetails?.externalJSON?.waitingTime,
            amount: currentInvoiceDetails?.externalJSON?.waitingCosts,
            tax: currentInvoiceDetails?.externalJSON?.waitingVat,
          },
          {
            id: newGuid(),
            description: "Disbursements",
            amount: currentInvoiceDetails?.externalJSON?.disbExVat,
            tax: currentInvoiceDetails?.externalJSON?.disbVat,
          },
        ]}
        showCellVerticalBorder
        showColumnVerticalBorder
        sx={{
          backgroundColor: "#fff",
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
          borderRadius: 0,
          ".MuiDataGrid-overlay": { color: "#0060bb" },
          ".MuiDataGrid-row:hover": {
            backgroundColor: "inherit",
          },
          ".MuiDataGrid-virtualScroller": { overflowX: "hidden" },
        }}
      />
    );
  };

  const renderTotals = () => {
    const totals = getTotals(invoice.init.billingStageId);

    return (
      <div className="displayFlex">
        <div className="flex1">
          <Typography fontWeight={600} marginTop={1} sx={{ color: "#0160aa" }}>
            Total Breakdown
          </Typography>
          <Typography color="grey" marginLeft={2}>
            <i>{totals.calculationLabel}</i>
          </Typography>
          <div style={{ display: "inline-flex", marginTop: 10 }}>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalAdjustments}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">ADJUSTMENTS</Typography>
            </div>
            <div className="invoice-breakdown-box">
              <NumberDisplay
                value={currentInvoiceDetails?.totalApplied}
                showPrefix
                showNegative={
                  invoice.init.isReversedOrReversal ||
                  Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                  toBeReversed
                }
                align="right"
                color="#0160aa"
              />
              <Typography align="right">TOTAL PAID</Typography>
            </div>
          </div>
        </div>
        <div>
          <div
            className="invoice-total-row"
            style={{
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>TOTAL</Typography>
            </div>
            <div style={{ width: 92 }}>
              <NumberDisplay
                value={totals.totalTime}
                notCurrency
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalCost}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
            <div style={{ width: 100 }}>
              <NumberDisplay
                value={totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-row">
            <div style={{ width: 90 }}>
              <Typography style={{ fontWeight: 600 }}>INC. TAX</Typography>
            </div>
            <div style={{ width: 192 }}>
              <NumberDisplay
                value={totals.totalCost + totals.totalVat}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
              />
            </div>
          </div>
          <div className="invoice-total-footer">
            <div style={{ width: 90 }}>
              <Typography style={{ color: "#db761d", fontWeight: 600 }}>
                BALANCE DUE
              </Typography>
            </div>
            <div style={{ width: 193 }}>
              <NumberDisplay
                value={calculateBalanceDue(
                  [
                    totals.totalCost,
                    totals.totalVat,
                    currentInvoiceDetails?.totalAdjustments || 0,
                  ],
                  [currentInvoiceDetails?.totalApplied || 0]
                )}
                showPrefix
                showNegative={
                  (!currentInvoiceDetails?.reversalId &&
                    (invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal))) ||
                  toBeReversed
                }
                align="right"
                bold
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <TopBar
        leftComponents={
          <>
            <InvoiceDeleteButton
              invoiceDetails={currentInvoiceDetails}
              invoiceRef={invoiceRef.current}
            />
            <Tooltip placement="bottom-end" title="Reverse Transaction">
              <span>
                <IconButton
                  disabled={
                    !currentInvoiceDetails?.invoiceId ||
                    invoice.init.isReversedOrReversal ||
                    Boolean(currentInvoiceDetails?.reversedOrReversal)
                  }
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    setToBeReversed(true);
                  }}
                >
                  <ReverseIcon
                    disabled={
                      !currentInvoiceDetails?.invoiceId ||
                      invoice.init.isReversedOrReversal ||
                      Boolean(currentInvoiceDetails?.reversedOrReversal)
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </>
        }
        centerComponents={
          <ReversalInfo
            isReversal={
              invoice.init.isReversedOrReversal ||
              Boolean(currentInvoiceDetails?.reversedOrReversal)
            }
          />
        }
        helpUrl={
          invoice.init.billingStageId === 1
            ? "https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Invoicing-a-Criminal-Investigations-Matter"
            : "https://community.leap.co.uk/s/article/Legal-Aid-Criminal-Invoicing-a-Criminal-Proceedings-Matter"
        }
        rightComponents={
          <>
            <Tooltip placement="bottom-end" title="Send to Word">
              <IconButton
                aria-controls="precedent-menu"
                disableRipple
                disableTouchRipple
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setPrecedentMenuAnchorEl(event.currentTarget);
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="precedent options" src={wordIcon} />
              </IconButton>
            </Tooltip>
            <Menu
              id="precedent-menu"
              anchorEl={precedentMenuAnchorEl}
              open={precedentMenuOpen}
              onClose={() => {
                setPrecedentMenuAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {(invoice.precedentList || []).map(
                (item: IPrecedentDto, index: number) => (
                  <MenuItem
                    key={`precedent-${index}`}
                    onClick={(e) => {
                      createInvoiceDocument(
                        invoice.init.isReversedOrReversal ||
                          Boolean(currentInvoiceDetails?.reversedOrReversal) ||
                          toBeReversed,
                        (message) => setNotificationMessage(message),
                        (id: string) => {
                          const staffId = !!decodedToken.current
                            ? decodedToken.current.staffId
                            : "";
                          dispatch(
                            createDocument({
                              precedentId: item.guid,
                              matterId: matterDetails?.matterId,
                              documentName: item.description,
                              isCustomPrecedent: false,
                              staffInitials: userList?.find(
                                (s) => s.staffId === staffId
                              )?.initials,
                              parameters: {
                                staffId: staffId,
                                invoiceId: invoice.invoice?.invoiceId,
                              },
                            } as IDocBuilderModelDto)
                          ).then((action: any) => {
                            const docModel = action.payload as IDocModelDto;
                            openDocument(
                              {
                                documentId: docModel.documentId,
                                matterId: matterDetails?.matterId,
                              },
                              sdkApi
                            );
                          });
                        },
                        (id) => dispatch(setPrecedentId(id)),
                        currentInvoiceDetails,
                        item.guid
                      );
                      setPrecedentMenuAnchorEl(null);
                    }}
                    value={item.guid}
                  >
                    {item.code} - {item.description}
                  </MenuItem>
                )
              )}
            </Menu>
            <Tooltip placement="bottom-end" title="Print">
              <IconButton
                disableRipple
                disableTouchRipple
                onClick={() => {
                  printReport(
                    () =>
                      setNotificationMessage(
                        "This transaction will be printed after saving."
                      ),
                    () =>
                      openReport(
                        currentInvoiceDetails?.invoiceId || "",
                        sdkApi
                      ),
                    () => dispatch(setPrintingEnabled(true)),
                    currentInvoiceDetails
                  );
                }}
                style={{ marginRight: 15 }}
              >
                <img alt="print" src={printerIcon} />
              </IconButton>
            </Tooltip>
          </>
        }
      />
      <div className="invoice-main">
        <>
          <div className="invoice-criteria">{renderCriteria()}</div>
          <div className="invoice-breakdown">{renderBreakdown()}</div>
          <div className="invoice-total">{renderTotals()}</div>
        </>
      </div>
      <Footer
        onCancel={() => {
          if (
            JSON.stringify(invoice.invoice) !==
            JSON.stringify(currentInvoiceDetails)
          ) {
            setShowUnsavedData(true);
          } else {
            handleClose();
          }
        }}
        onSave={() => {
          if (toBeReversed) {
            if (currentInvoiceDetails?.invoiceId) {
              dispatch(reverseInvoice(currentInvoiceDetails))
                .unwrap()
                .then(() => {
                  dispatch(
                    clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                  )
                    .then(() => close(sdkApi))
                    .catch((e) => console.error(e));
                });
            }
          } else {
            if (
              !!currentInvoiceDetails &&
              !validate(
                getRequiredFields(currentInvoiceDetails),
                [],
                (errors) => dispatch(setValidationErrors(errors))
              )?.length &&
              !saving
            ) {
              const totals = getTotals(invoice.init.billingStageId);
              if (totals.totalCost + totals.totalVat > 0) {
                dispatch(setLoader(true));
                if (currentInvoiceDetails.invoiceId) {
                  if (
                    currentInvoiceDetails.status !== invoice.invoice?.status
                  ) {
                    dispatch(
                      updateInvoice(
                        convertFormModelToInvoice(currentInvoiceDetails)
                      )
                    )
                      .unwrap()
                      .then(() => {
                        dispatch(
                          clearSemaphore(
                            currentInvoiceDetails.semaphoreId || ""
                          )
                        )
                          .then(() => close(sdkApi))
                          .catch((e) => console.error(e));
                      });
                  } else {
                    dispatch(
                      clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                    )
                      .then(() => close(sdkApi))
                      .catch((e) => console.error(e));
                  }
                } else {
                  dispatch(
                    createInvoice(
                      convertFormModelToInvoice(currentInvoiceDetails)
                    )
                  )
                    .unwrap()
                    .then((payload: IInvoiceDto) => {
                      dispatch(
                        clearSemaphore(currentInvoiceDetails.semaphoreId || "")
                      ).then(() => {
                        if (invoice.precedentId) {
                          const staffId = !!decodedToken.current
                            ? decodedToken.current.staffId
                            : "";
                          dispatch(
                            createDocument({
                              precedentId: invoice.precedentId,
                              matterId: payload.matterId,
                              documentName: invoice.precedentList?.find(
                                (item) => item.guid === invoice.precedentId
                              )?.description,
                              staffInitials: userList?.find(
                                (s) => s.staffId === staffId
                              )?.initials,
                              parameters: {
                                invoiceId: payload.invoiceId,
                                staffId: staffId,
                              },
                            } as IDocBuilderModelDto)
                          ).then((action: any) => {
                            const docModel = action.payload as IDocModelDto;
                            openDocument(
                              {
                                documentId: docModel.documentId,
                                matterId: payload.matterId,
                              },
                              sdkApi
                            );
                            if (
                              invoice.isPrintingEnabled &&
                              docModel.documentId &&
                              payload.invoiceId
                            ) {
                              openReport(payload.invoiceId, sdkApi).then(() =>
                                close(sdkApi)
                              );
                            } else {
                              close(sdkApi);
                            }
                          });
                        } else if (
                          invoice.isPrintingEnabled &&
                          payload.invoiceId
                        ) {
                          openReport(payload.invoiceId, sdkApi).then(() =>
                            close(sdkApi)
                          );
                        } else close(sdkApi);
                      });
                    })
                    .catch((e) => console.error(e));
                }
              } else
                dispatch(
                  setValidationErrors([
                    "You cannot create an invoice with a value of £0.00",
                  ])
                );
            }
          }
        }}
      />
      <CustomDialog
        actions={
          <>
            <Button
              color="primary"
              disableRipple
              onClick={() => {
                setNotificationMessage(undefined);
              }}
              variant="contained"
            >
              OK
            </Button>
          </>
        }
        content={<Typography>{notificationMessage}</Typography>}
        isOpen={notificationMessage !== undefined}
        onClose={() => setNotificationMessage(undefined)}
        title="Notification"
      />
      <UnsavedDataDialog
        isOpen={showUnsavedData}
        onClose={() => {
          setShowUnsavedData(false);
        }}
        onYesCallback={() => handleClose()}
      />
      <InvoiceErrorDialog />
    </>
  );
};

export default InvoiceCrimeDetails;
